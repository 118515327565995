import * as React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Typography } from '@material-ui/core';
import Link from '@mui/material/Link';
import { Stack } from 'react-bootstrap';

export default function AccordionUsage() {
  const qandas = [
    {
      question: "How does NesisAI work?",
      answer: "NesisAI is an on-premises alternative to AI tools like ChatGPT that operates on your data center or local server behind your firewall. This enables our customers to utilize Large Language Models (LLMs) with confidence, even when handling sensitive and proprietary information under stringent regulations."
    },
    {
      question: "What are the advantages of an on-premise system versus a SaaS one?",
      answer: "Unlike other AI software that necessitates transmitting sensitive data over the internet, NesisAI operates on-premise, keeping data private and usable even in environments where ChatGPT-like tools are prohibited. On-premise and private cloud deployments enable data-sensitive companies and those in regulated industries to adopt AI securely."
    },

    {
      question: "What kind of data does NesisAI work with?",
      answer: "NesisAI allows you to connect to your usual data sources, upload files, and create new content within the tool. Any data linked to NesisAI remains entirely within your system or environment."
    },

    {
      question: "Can I try it out before making a commitment?",
      answer: "Yes, NesisAI is opensource, you can install it on your servers and try it out. We would be happy to understand your use case better and help you test the right solution for it, whether through our Sandbox (Test) environment or by setting up a pilot project.",
      links: [
        {
          label: 'Github Repository',
          link: 'https://github.com/ametnes/nesis'
        },
        {
          label: 'Documentation',
          link: 'https://ametnes.github.io/nesis/'
        },
        {
          label: 'Installing on Kubernetes',
          link: 'https://ametnes.github.io/nesis/installing/helm/'
        }
      ]
    },

    {
      question: "How can I integrate NesisAI into my company?",
      answer: "NesisAI can be seamlessly integrated with most setups, from private clouds to data centers. Our solution architects will collaborate closely with your IT team to ensure a smooth integration process, typically completed within a matter of days."
    },


    {
      question: "Do you offer a dedicated tech team to implement your solution?",
      answer: "The NesisAI implementation process is straightforward and efficient. Our solution architects will provide the tools, expertise, and ongoing support needed to ensure a seamless integration."
    },


    {
      question: "What am I paying for?",
      answer: "Companies that use NesisAI often see a quick return on investment due to the operational optimization provided by AI in their daily processes. NesisAI simplifies the technical complexities of creating, deploying, and running a fully private AI platform. You are investing in your own private, end-to-end, state-of-the-art collaborative AI environment, saving both the time and resources required to develop and maintain an in-house AI solution."
    },


    {
      question: "Will NesisAI be updated periodically? How often and what is the process to do so?",
      answer: "NesisAI will receive updates that enhance both its application layer—offering improved workflows and functionalities—and its LLM layer, ensuring your AI platform remains current with the latest available technologies. Stay up-to-date in this fast-evolving field."
    },

  ]

  return (
    <Box sx={{ mt: 3 }}>
      <Typography variant="h4" gutterBottom>
        <b>Frequently Asked Questions (FAQs)</b>
      </Typography>

      {qandas.map((pair, idx) => (
        <Accordion key={idx}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography variant="h5" gutterBottom>
              {pair.question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              <Typography>
                {pair.answer}
              </Typography>
              <Stack
                spacing={{ xs: 1, sm: 2 }}
                direction="row"
                useFlexGap="false"
              >
                {(pair.links || []).map((link, idx) => (
                  <a key={idx} href={link.link}>{link.label}</a>
                ))}
              </Stack>
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
}
